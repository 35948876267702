import { isTouchDevice } from "../utils"

const Carousel = function(el) {
    this.el = el

    let inner
    let slider
    let prevButton
    let nextButton

    this.init = () => {
        inner = this.el.querySelector('.pl-carousel__inner')
        if (!inner) return

        slider = new KeenSlider(inner, {
            selector  : '.pl-carousel__item',
            loop      : false,
            mode      : 'free',
            rubberband: false,
            rtl       : false,
            slides    : { perView: 'auto' },
            created   : () => {
                this.el.classList.add('has-slider-js')
            }
        }, 
        [this.wheelControls]
        )

        if (slider.track.details.length <= 0) {
            this.destroy()
        }

        prevButton = this.el.querySelector('.pl-carousel__nav-button.is-prev')
        nextButton = this.el.querySelector('.pl-carousel__nav-button.is-next')
        
        if ( prevButton ) prevButton.addEventListener('click', slider.prev)
        if ( nextButton ) nextButton.addEventListener('click', slider.next)

        if ( prevButton && nextButton ) {
            this.setDisabledNav()
            slider.on('slideChanged', this.setDisabledNav)
        }
    }

    this.setDisabledNav = () => {
        const relIdx = slider.track.details.rel // Current
        const minIdx = slider.track.details.min
        const maxIdx = slider.track.details.max
        const totalSlides = slider.track.details.slidesLength
        
        nextButton.classList.remove('disabled')
        prevButton.classList.remove('disabled')

        if (relIdx == maxIdx) {
            nextButton.classList.add('disabled')
        }
        if (relIdx == minIdx) {
            prevButton.classList.add('disabled')
        }
    }

    this.wheelControls = (slider) => {
        let touchTimeout
        let position
        let wheelActive

        function dispatch(e, name) {
            position.x -= e.deltaX
            position.y -= e.deltaY
            slider.container.dispatchEvent(
                new CustomEvent(name, {
                    detail: {
                        x: position.x,
                        y: position.y,
                    },
                })
            )
        }

        function wheelStart(e) {
            position = {
                x: e.pageX,
                y: e.pageY,
            }
            dispatch(e, "ksDragStart")
        }

        function wheel(e) {
            dispatch(e, "ksDrag")
        }

        function wheelEnd(e) {
            dispatch(e, "ksDragEnd")
        }

        function eventWheel(e) {
            let direction = Math.abs(e.deltaX) > Math.abs(e.deltaY) ? 'horizontal' : 'vertical'

            if (direction == 'horizontal') {
                e.preventDefault()
    
                if (!wheelActive) {
                    wheelStart(e)
                    wheelActive = true
                }
                wheel(e)

                clearTimeout(touchTimeout)
                touchTimeout = setTimeout(() => {
                    wheelActive = false
                    wheelEnd(e)
                }, 50)
            }
        }

        slider.on('created', () => {
            slider.container.addEventListener('wheel', eventWheel, { passive: false })
        })
    }

    this.update = () => {
        if (slider) {
            slider.update()
        }
    }

    this.destroy = () => {
        if (slider) {
            slider.destroy()
            this.el.classList.remove('has-slider-js')
        }
    }
}

// const Carousels = new function() {
//     let container = document
//     let carousels = []

//     this.init = (newContainer = document) => {
//         if (isTouchDevice()) return
//         if (newContainer) container = newContainer
        
//         document.querySelectorAll('.pl-carousel').forEach(carousel => {
//             carousels.push(new Carousel(carousel))
//         })

//         carousels.forEach(carousel => carousel.init())
//     }

//     this.update = (newContainer) => {
//         carousels = []
//         this.init(newContainer)
//     }
// }

export { Carousel }