import Scrollbar from 'smooth-scrollbar';
import { ScrollCustomPlugin } from './scrollCustomPlugin.js';
import { isTouchDevice } from '../../utils';

Scrollbar.use(ScrollCustomPlugin);

const Scroll = new function () {
    this.scrollbar = {}

    this.settings = {
        container: document.querySelector('[data-scroll-wrapper]'),
        smoothness: 92
    }

    this.init = (settings) => {
        if (isTouchDevice()) {
            if (document.body.classList.contains('smooth-scroll')) {
                document.body.classList.remove('smooth-scroll')
            }
            
            return
        }
        
        if (settings) this.settings = Object.assign(this.settings, settings)
        
        this.scrollbar = Scrollbar.init(this.settings.container, {
            damping: (101 - this.settings.smoothness) / 100,
            delegateTo: document,
            alwaysShowTracks: true,
            plugins: {
                ScrollCustomPlugin: {},
            },
        })

        this.scrollbar.track.xAxis.element.remove()

        ScrollTrigger.scrollerProxy(this.settings.container, {
            scrollTop: (value) => {
                if (arguments.length) {
                    this.scrollbar.scrollTop = value
                }
                return this.scrollbar.scrollTop
            }
        })

        this.scrollbar.addListener(ScrollTrigger.update)
        ScrollTrigger.defaults({ scroller: this.settings.container })

        this.setupStickyElements()
    }

    this.setupStickyElements = () => {
        const stickyElements = this.settings.container.querySelectorAll('.is-sticky')
        if (!stickyElements) return

        stickyElements.forEach(el => {
            ScrollTrigger.create({
                trigger: el,
                end: el.parentElement.offsetHeight,
                pin: true,
                pinSpacing: false,
            })
        })
    }

    this.scrollTo = (target) => {
        const yPos = this.settings.container.querySelector(target).getBoundingClientRect().top + this.scrollbar.offset.y
        gsap.to(this.scrollbar, { 
            scrollTo: { y: yPos },
            ease: Power4.easeOut,
            duration: .9,
        })
    }

    this.pause = () => {
        this.scrollbar.updatePluginOptions('customScroll', { pause: true })
    }

    this.unpause = () => {
        this.scrollbar.updatePluginOptions('customScroll', { pause: false })
    }
}

export default Scroll