import { win, loading } from '../globals'

  // Set win
const setWin = () => {
	win.w = window.innerWidth
	win.h = window.innerHeight
}


  // Is Loading
const isLoading = () => loading.value


  // Set loading
const setLoading = (newVal) => {
	loading.value = newVal
	if (isLoading()) document.body.setAttribute('data-loading', 'loading')
	else document.body.removeAttribute('data-loading')
}


  // Set Vh
const setVh = () => {
	document.documentElement.style.setProperty('--vh', win.h / 100 + 'px')
}


  // Set header height
const setHeaderHeight = () => {
	const siteHeader = document.querySelector('.pl-header')
	document.documentElement.style.setProperty('--header-height', siteHeader.offsetHeight + 'px')
}


  // Set header height
const setFooterHeight = () => {
	const siteFooter = document.querySelector('.pl-footer')
	document.documentElement.style.setProperty('--footer-height', siteFooter.offsetHeight + 'px')
}


  // Is touch device
const isTouchDevice = () => "ontouchstart" in document.documentElement


  // Element is in viewport
const isInViewport = (element) => {
	const rect = element.getBoundingClientRect()

	return rect.bottom > 0 &&
		rect.right > 0 &&
		rect.left < (win.w || document.documentElement.clientWidth) &&
		rect.top < (win.h || document.documentElement.clientHeight)
}


  // Async delay
const delay = (ms = 0) => {
	return new Promise((resolve) => {
		setTimeout(resolve, ms)
	})
}


  // Videos loaded
const videosLoaded = (container = document) => {
	const videos       = container.querySelectorAll('video')
	const videosLength = videos.length
	let   videosLoaded = 0
	
	return new Promise((resolve) => {
		if (!videosLength) resolve()
		
		videos.forEach(video => {
			// video.autoplay = true
			
			video.addEventListener('loadeddata', () => {
				videosLoaded++
				// console.log('videos loaded: ' + videosLoaded + ' of ' + videosLength)
				if (videosLoaded == videosLength) {
					// console.log('videos loaded')
					resolve()
				}
			})
		})
	})
}


  // Wrap element
const wrap = (el, wrapper) => {
	if (el && el.parentNode) {
		el.parentNode.insertBefore(wrapper, el)
		wrapper.appendChild(el)
	}
}


  // Unwrap element
const unwrap = (el) => {
	const wrapper = el.parentNode
	if (el && wrapper) {
		while (wrapper.firstChild) {
			wrapper.parentNode.insertBefore(el, wrapper)
		}
		wrapper.remove()
	}
}


  // Leading zero numbers
const leadingZero = (num, size) => {
	num = num.toString()
	while (num.length < size)
		num = "0" + num

	return num
}


// Set iframes aspect ratio
const setIframeAspectRatio = (wrapper, iframe) => {
	const width   = iframe.getAttribute('width')
	const height  = iframe.getAttribute('height')
	// const padding = ( height / width ) * 100
	// wrapper.style.paddingBottom = padding + '%'
	wrapper.style.aspectRatio = width + ' / ' + height
}


  // Get UTC time
const getTime = (
	hoursElements,
	minutesElements,
	ampmElements,
	GMToffset) => {

	updateTime()
	let interval = setInterval(updateTime, 1000)

	function updateTime() {
		let date = new Date()

		let UTCHours   = date.getUTCHours() + GMToffset
		let UTCMinutes = date.getUTCMinutes()
		let hours      = (UTCHours + 24) % 12 || 12
		if (hours == 0) hours = 12
		let ampm       = UTCHours > 11 ? 'PM' : 'AM'
		let minutes    = UTCMinutes

		hoursElements.forEach(hoursElement => hoursElement.innerHTML = leadingZero(parseInt(hours), 2))
		minutesElements.forEach(minutesElement => minutesElement.innerHTML = leadingZero(parseInt(minutes), 2))
		ampmElements.forEach(ampmElement => ampmElement.innerHTML = ampm)
	}
}


export {
	setWin,
	setLoading,
	isLoading,
	setVh,
	setHeaderHeight,
	setFooterHeight,
	isTouchDevice,
	isInViewport,
	delay,
	videosLoaded,
	wrap,
	unwrap,
	leadingZero,
	setIframeAspectRatio,
	getTime,
}