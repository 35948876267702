import { scroller } from '../constants'
import { isInViewport } from '../utils'

const VideoSetup = new function () {
    const selector    = 'video'
    const inviewClass = 'video-play-inview'
    
    let container = document
    let videos    = []
    let triggers  = []

    this.init = (newContainer = document) => {
        if (newContainer) container = newContainer

        container.querySelectorAll(selector).forEach(video => {
            videos.push(video)
        })

        videos.forEach((video, index) => {
            if (video.classList.contains(inviewClass)) {
                // console.log('video '+ index +' init')
                video.play()

                const trigger = ScrollTrigger.create({
                    id: 'videoScrollTrigger-' + index,
                    trigger: video,
                    scroller: scroller,
                    onEnter: () => {
                        // console.log('video ' + index + ' play')
                        video.play()
                    },
                    onEnterBack: () => {
                        // console.log('video ' + index + ' play')
                        video.play()
                    },
                    onLeave: () => {
                        // console.log('video ' + index + ' pause')
                        video.pause()
                    },
                    onLeaveBack: () => {
                        // console.log('video ' + index + ' pause')
                        video.pause()
                    },
                    // markers: true,
                })

                // const trigger = ScrollTrigger.getById('videoScrollTrigger-' + index)
                triggers.push(trigger)

                video.addEventListener('loadeddata', () => {
                    // console.log('video '+ index +' loaded')
                    if (!isInViewport(video)) {
                        // console.log('video '+ index +' is out viewport')
                        video.pause()
                    } else {
                        // console.log('video '+ index +' is in viewport')
                        // video.play()
                    }
                    trigger.refresh()
                }, false)
            }
        })
    }

    this.refresh = () => {
        videos.forEach(video => {
            if (video.classList.contains(inviewClass)) {
                video.pause()
                if (isInViewport(video)) video.play()
            }
        })
        triggers.forEach(trigger => trigger.refresh())
    }

    this.update = (container = document) => {
        videos = []
        triggers.forEach(trigger => trigger.kill())
        triggers = []
        this.init(container)
    }
}

export default VideoSetup