import { win } from '../globals'

const MobileMenu = new function () {
    let nav      = null
    let navLinks = null
    let button   = null
    let logo     = null

    this.init = () => {
        if (win.w > 1199) return

        nav      = document.querySelector('.pl-nav-mobile-container')
        navLinks = document.querySelectorAll('.pl-nav-mobile-container a')
        button   = document.querySelector('.pl-nav-button')
        logo     = document.querySelector('.pl-logo a')

        button.addEventListener('click', this.openMenu)
    }

    this.openMenu = (e) => {
        button.removeEventListener('click', this.openMenu)
        button.classList.add('is-open')
        nav.classList.add('is-open')
        logo.addEventListener('click', this.closeMenu)
        navLinks.forEach(link => link.addEventListener('click', this.closeMenu))
        button.addEventListener('click', this.closeMenu)
    }

    this.closeMenu = (e) => {
        button.addEventListener('click', this.openMenu)
        button.classList.remove('is-open')
        nav.classList.remove('is-open')
        logo.removeEventListener('click', this.closeMenu)
        navLinks.forEach(link => link.removeEventListener('click', this.closeMenu))
        button.removeEventListener('click', this.closeMenu)
    }

    this.update = () => {
        nav      = null
        navLinks = null
        button   = null

        this.init()
    }
}

export default MobileMenu