import { scroller } from '../constants'
import { isInViewport } from '../utils'

const Inview = new function() {
    const selector = '[data-inview]'
    let container = document
    let elements = []
    let triggers = []

    const animations = {
        fadeIn: {
            set: {
                opacity: 0,
            },
            anim: {
                opacity: 1,
                delay: .15,
                duration: .3,
            },
        },
        fadeInUp: {
            set: {
                opacity: 0,
                y: '1rem',
            },
            anim: {
                opacity: 1,
                y: '0',
                ease: 'power4.out',
                delay: .15,
                duration: .45,
            },
        }
    }

    this.init = (newContainer = document) => {
        if (newContainer) container = newContainer

        container.querySelectorAll(selector).forEach(element => {
            elements.push({
                el: element,
                set: animations[element.dataset.inview].set,
                anim: animations[element.dataset.inview].anim,
            })
        })

        elements.forEach((element, index) => { 
            // console.log('Element ' + index + ' is in viewport: ' + isInViewport(element.el))
            if (!isInViewport(element.el)) {
                gsap.set(element.el, element.set)
            }

            const trigger = ScrollTrigger.create({
                id: 'inviewScrollTrigger-' + index,
                trigger: element.el,
                scroller: scroller,
                onEnter: () => {
                    gsap.to(element.el, element.anim)
                    // console.log('Element ' + index + ' enter')
                },
                onEnterBack: () => {
                    gsap.to(element.el, element.anim)
                    // console.log('Element ' + index + ' enter')
                },
                // markers: true,
            })

            triggers.push(trigger)
        })
    }

    this.killTriggers = () => {
        triggers.forEach(trigger => trigger.kill())
    }

    this.update = (container = document) => {
        elements = []
        triggers = []
        this.init(container)
    }
}

export default Inview