const pageTransition = {
    // Page out
    out: (content, onStart, onComplete) => {
        if (!content) return
        if (onStart) onStart()

        gsap.to(content, {
            autoAlpha: 0,
            duration: .2,
            onComplete: () => {
                onComplete ? onComplete() : null
            }
        })
    },

    // Page set
    set: (content) => {
        if (!content) return
        gsap.set(content, { 
            autoAlpha: 0, 
            y: '1rem' 
        })
    },

    // Page in
    in: (content, onStart, onComplete) => {
        if (!content) return
        if (onStart) onStart()

        gsap.set(content, { 
            autoAlpha: 0, 
            y: '1rem' 
        })

        gsap.timeline({
            onComplete: () => {
                onComplete ? onComplete() : null
                ScrollTrigger.refresh()
            }
        })
        .to(content, {
            y: 0,
            duration: .6,
            ease: 'expo.out',
        })
        .to(content, {
            autoAlpha: 1,
            duration: .3,
        }, 0.1)
    },
}

export { pageTransition }