const Accordions = new function () {
    let container = document
    let elements  = []

    this.init = (newContainer = document) => {
        container = newContainer

        document.querySelectorAll('.pl-accordion').forEach(element => {
            elements.push({
                el           : element,
                header       : element.querySelector('.pl-accordion__header'),
                content      : element.querySelector('.pl-accordion__content'),
                contentHeight: 0,
            })
            const lastItem               = elements[elements.length - 1]
                  lastItem.contentHeight = lastItem.content.offsetHeight
        })

        elements.forEach(element => {
            this.closeAccordion(element)
            element.header.addEventListener('click', () => this.openAccordion(element))
        })
    }

    this.openAccordion = (element) => {
        element.el.classList.remove('is-closed')
        element.header.removeEventListener('click', () => this.openAccordion(element))
        element.header.addEventListener('click', () => this.closeAccordion(element))
    }

    this.closeAccordion = (element) => {
        element.el.classList.add('is-closed')
        element.header.removeEventListener('click', () => this.closeAccordion(element))
        element.header.addEventListener('click', () => this.openAccordion(element))
    }

    this.update = (container = document) => {
        elements = []
        this.init(container)
    }
}

export default Accordions