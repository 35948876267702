import { scroller } from '../constants'
import { wrap, unwrap } from '../utils'
import { win } from '../globals'
import VideoSetup from '../commons/videoSetup'
import Inview from '../commons/inview'

const PortfolioTabs = new function () {
    let container    = document
    let tabsWrapper  = null
    let tabsElemens  = null
    let itemsWrapper = null
    let itemsElemens = null

    let tabs  = []
    let items = []

    // Init component
    this.init = (newContainer = document) => {
        container   = newContainer
        tabsWrapper = container.querySelector('.pl-tabs')
        if (!tabsWrapper) return

        tabsElemens  = tabsWrapper.querySelectorAll('.pl-tabs__item')
        itemsWrapper = container.querySelector('.pl-portfolio-gallery')
        itemsElemens = itemsWrapper.querySelectorAll('.pl-portfolio-item')

        tabsElemens.forEach(element => {
            tabs.push({
                el    : element,
                link  : element.querySelector('a'),
                filter: element.dataset.filter,
            })
        })

        itemsElemens.forEach(element => {
            items.push({
                el  : element,
                cats: element.dataset.categories ? JSON.parse(element.dataset.categories): [],
            })
        })

        tabs.forEach(tab => {
            tab.link.addEventListener('click', (e) => {
                e.preventDefault()
                this.activateTab(tab)

                this.hideItems(() => {
                    this.filterItems(tab.filter)
                    this.fixScrollPosition()
                    this.showItems(() => {
                        Inview.killTriggers()
                        Inview.update()
                    })
                })
            })
        })
    }

      // Activate tab
    this.activateTab = (tab) => {
        tabs.forEach(t => t.el.classList.remove('active'))
        tab.el.classList.add('active')
        tabsWrapper.dataset.active = tab.filter
    }

    // Filter items
    this.filterItems = (filter) => {
        this.resetFilters()
        if (filter == '*') return

        itemsWrapper.classList.add('has-filter')
        items.forEach(item => {
            if (!isActive(filter, item)) {
                const wrapper           = document.createElement('div')
                      wrapper.className = 'hidden'
                wrap(item.el, wrapper)
            }
        })
    }

    // Reset filters
    this.resetFilters = () => {
        itemsWrapper.classList.remove('has-filter')
        items.forEach(item => {
            const wrapper = item.el.parentNode
            if (wrapper.classList.contains('hidden')) {
                unwrap(item.el)
            }
        })
    }

    // Fix the scroll position after filter
    this.fixScrollPosition = () => {
        let scrollOffset = 0
        if (win.w < 900) { // Is mobile or tablet-v
            const headerHeight = document.querySelector('.pl-header').offsetHeight
                  scrollOffset = itemsWrapper.getBoundingClientRect().top + scroller.scrollTop - tabsWrapper.offsetHeight - headerHeight
        } else { // Is desktop or tablet-h
            scrollOffset = itemsWrapper.getBoundingClientRect().top + scroller.scrollTop - tabsWrapper.offsetHeight
        }

        if (scroller.scrollTop > scrollOffset) {
            scroller.scrollTop = scrollOffset
        }
    }

    // Hide items transition
    this.hideItems = (callback) => {
        const onComplete = () => {
            if (callback && typeof callback == 'function') {
                callback()
            }
        }
        gsap.to(itemsWrapper, {
            autoAlpha : 0,
            duration  : .2,
            onComplete: onComplete
        })
    }

    // Show items transition
    this.showItems = (callback) => {
        VideoSetup.refresh()
        
        const onComplete = () => {
            if (callback && typeof callback == 'function') callback()
        }

        gsap.set(itemsWrapper, { autoAlpha: 0, y: '1rem' })

        gsap.timeline({
            delay     : .3,
            onComplete: onComplete
        })
            .to(itemsWrapper, {
                y       : 0,
                duration: .6,
                ease    : 'expo.out',
            })
            .to(itemsWrapper, {
                autoAlpha: 1,
                duration : .3,
            }, 0.1)
    }

    // Update component
    this.update = (container = document) => {
        tabsWrapper  = null
        tabsElemens  = null
        itemsWrapper = null
        itemsElemens = null
        tabs         = []
        items        = []

        this.init(container)
    }
}


// Util
function isActive(filter, item) {
    let show = false
    item.cats.forEach(category => {
        if (category == filter) show = true
    })
    return show
}


export default PortfolioTabs