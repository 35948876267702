import { getTime } from '../utils'

const Clock = new function () {
    let container  = document
    let gmtOffset  = null
    let hoursEls   = null
    let minutesEls = null
    let ampmEls    = null

    this.init = (newContainer = document) => {
        container = newContainer
        gmtOffset = parseInt(container.querySelector('.pl-time').dataset.gmtOffset)

        hoursEls   = container.querySelectorAll('.pl-time__hours')
        minutesEls = container.querySelectorAll('.pl-time__minutes')
        ampmEls    = container.querySelectorAll('.pl-time__ampm')

        getTime(hoursEls, minutesEls, ampmEls, gmtOffset)
    }

    this.update = (container = document) => {
        gmtOffset  = null
        hoursEls   = null
        minutesEls = null
        ampmEls    = null

        this.init(container)
    }
}

export default Clock