import { delay } from '../utils'

const VimeoPlayer = function(el) {
    this.el     = null
    this.player = null

    let iframe     = null
    let playButton = null
    let cover      = null

    this.init = () => {
        this.el    = el
        iframe     = el.querySelector('iframe')
        playButton = el.querySelector('.pl-vimeo-player__play-button')
        cover      = el.querySelector('.pl-vimeo-player__cover')

        if (!iframe) return

        this.player = new Vimeo.Player(iframe)
        
        if (playButton) {
            playButton.addEventListener('click', this.playVideo)
        }
        
        this.player.on('ended', () => {
            this.restoreCover()
            this.exitFullscreen()
        })

        this.exitFullscreen = () => {
            if (document.fullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen()
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if ( document.webkitCancelFullScreen ) {
                    document.webkitCancelFullScreen()
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen()
                }
            }
        }
    }

    this.playVideo = async () => {
        this.player.play()
        await delay(200)
        this.el.classList.add('uncover')
    }
    
    this.restoreCover = () => {
        this.el.classList.remove('uncover')
        this.player.pause()
    }
}

const VimeoPlayers = new function() {
    let container = document
    let players = []

    this.init = (newContainer = document) => {
        container = newContainer
        
        container.querySelectorAll('.pl-vimeo-player').forEach(element => {
            players.push(new VimeoPlayer(element))
        })
        players.forEach(player => player.init())
    }

    this.update = (container = document) => {
        players = []
        this.init(container)
    }
}

export default VimeoPlayers