import '../scss/main.scss'

gsap.registerPlugin(ScrollTrigger)

import Navigation from './navigation/navigation'
import Scroll from './commons/smooth-scroll/scroll'
import VideoSetup from './commons/videoSetup'
import Inview from './commons/inview'
import VimeoPlayers from './commons/vimeoPlayers'

import MobileMenu from './components/mobileMenu'
import PortfolioTabs from './components/portfolioTabs'
import Archive from './components/archive'
import Accordions from './components/accordions'
import Clock from './components/clock'

import { pageTransition } from './transitions/pageTransition'
import { setWin, setVh, setHeaderHeight, setFooterHeight, setLoading, videosLoaded, delay, isTouchDevice, setIframeAspectRatio } from './utils'

const App = new function () {
	this.init = () => {
		const initialLoading = async () => {
			const content = document.querySelector('[data-content]')
			const cover   = document.querySelector('.pl-site-cover')

			pageTransition.set(content)
			setLoading(true)

			let startTime = performance.now()
			
			if (navigator.userAgent.indexOf("Safari") > -1 || /iPad|iPhone|iPod/.test(navigator.userAgent)) {
			} else {
				await videosLoaded(content)
			}

			let endTime = performance.now()
			if (endTime - startTime < 400) 
				await delay(600)

			setLoading(false)
			cover.style.display = 'none'
			pageTransition.in(content)
		}

		initialLoading()

		setWin()
		setVh()
		setHeaderHeight()
		setFooterHeight()

		if (isTouchDevice()) document.body.classList.add('is-touch-device')
		window.addEventListener('resize', this.onResize)

		// Iframes aspect ratio
		document.querySelectorAll('.set-iframe-aspect-ratio').forEach(iframeWrapper => {
			setIframeAspectRatio(iframeWrapper, iframeWrapper.querySelector('iframe'))
		})

		if (wp_settings.smooth_scroll && !isTouchDevice()) {
			Scroll.init({ smoothness: wp_settings.scroll_smoothness })
		} else {
			Scroll.scrollbar = null
		}

		Navigation.init()
		document.addEventListener('newContentLoad', this.onNewContentLoad)

		Inview.init()
		MobileMenu.init()
		VideoSetup.init()
		VimeoPlayers.init()
		PortfolioTabs.init()
		Archive.init()
		Accordions.init()
		Clock.init()
	}

	this.onNewContentLoad = async (e) => {
		const newContainer = e.detail.container

		let Alltrigger = ScrollTrigger.getAll()
		for (let i = 0; i < Alltrigger.length; i++) {
			Alltrigger[i].kill(true)
		}

		e.detail.container.querySelectorAll('.set-iframe-aspect-ratio').forEach(iframeWrapper => {
			setIframeAspectRatio(iframeWrapper, iframeWrapper.querySelector('iframe'))
		})

		PortfolioTabs.update(newContainer)
		Archive.update(newContainer)
		Accordions.update(newContainer)
		Clock.update(newContainer)
		VideoSetup.update(newContainer)
		VimeoPlayers.update(newContainer)

		await delay(300)
		Inview.update(newContainer)
	}

	this.onResize = () => {
		setWin()
		setVh()
		setHeaderHeight()
		setFooterHeight()
	}
}

window.addEventListener('load', App.init)
