require("babel-polyfill")
import { pageTransition } from '../transitions/pageTransition'
import Scroll from '../commons/smooth-scroll/scroll'
import { scroller } from '../constants'
import { setLoading, videosLoaded, delay } from '../utils'

const Navigation = new function () {
    // Dom selectors
    const wrapperSelector = '[data-content-wrapper]'
    const contentSelector = '[data-content]'
    const dataSelector = '#page-data'

    this.main = document.querySelector(wrapperSelector)
    this.popstate = false

    let links = []
    let anchorLinks = []

    // Custom events
    this.events = {
        newContentLoad: null
    }

    // init
    this.init = () => {
        this.setLinks()
        this.setActiveMenuItem()
        window.addEventListener('popstate', this.onBrowserBack, false)
    }

    this.onNewContentLoad = () => {
        this.setLinks()
    }

    // Set active menu item
    this.setActiveMenuItem = (newUrl) => {
        const url = newUrl ? newUrl : window.location.href
        const currentActiveItems = document.querySelectorAll('.menu-item.current-menu-item')
        currentActiveItems.forEach(menuItem => menuItem.classList.remove('current-menu-item'))
        const nextActiveItems = document.querySelectorAll(`.menu-item > a[href="${url}"]`)
        nextActiveItems.forEach(menuItem => menuItem.closest('.menu-item').classList.add('current-menu-item'))
    }

    // Set internal links
    this.setLinks = () => {
        links = []
        anchorLinks = []
        document.querySelectorAll('a').forEach(link => {
            if (
                !link.matches('[target="_blank"]')
                &&
                !link.matches('[href^="#"]')
                &&
                !link.matches('[href^="mailto:"]')
                &&
                !link.matches('[href^="tel:"]')
                &&
                !link.matches('.wpml-ls-link')
            ) {
                links.push(link)

            } else if (link.matches('[href^="#"]')) {
                anchorLinks.push(link)

            } else if (link.matches('.wpml-ls-link')) {
                link.addEventListener('click', () => {
                    pageTransition.out(document.body, null, null)
                }, false)
            }
        })
        links.forEach(link => link.addEventListener('click', this.onLinkClick, false))
        anchorLinks.forEach(link => link.addEventListener('click', this.onAnchorLinkClick, false))
    }

    // On links click
    this.onLinkClick = (e) => {
        e.preventDefault()
        const newUrl = e.currentTarget.href
        const oldContent = document.querySelector(contentSelector)
        // Page out
        this.leavePage(newUrl, oldContent)
    }

    // On anchor link click
    this.onAnchorLinkClick = (e) => {
        e.preventDefault()

        const link = e.target.closest('a')
        if (!link.hash) return
        const targetElement = document.querySelector(link.hash)
        const y = targetElement.getBoundingClientRect().top

        if (Scroll.scrollbar) {
            Scroll.scrollbar.scrollTo(0, y, 600)
        } else {
            scroller.scrollTo({
                top: y,
                behavior: 'smooth',
            })
        }
    }

    // On browser back (popstate)
    this.onBrowserBack = () => {
        this.popstate = true
        const newUrl = location.href
        const oldContent = document.querySelector(contentSelector)
        // Page out
        this.leavePage(newUrl, oldContent)
    }

    // Leave page
    this.leavePage = (newUrl, oldContent) => {
        let newContent = null
        let contentLoaded = false

        const onTransitionStart = async () => {
            setLoading(true)
            document.body.classList.add('on-transition')

            // Update menu active item
            this.setActiveMenuItem(newUrl)

            // let startTime = performance.now()
            let response = await fetch(newUrl)
            // let endTime = performance.now()

            // console.log(newUrl)
            // console.log(`Fetch delay: ${endTime - startTime} ms`)

            if (response.ok) {
                newContent = await response.text()
                contentLoaded = true
            } else {
                alert("Error-HTTP: " + response.status)
            }
        }

        const onTransitionEnd = async () => {
            // Minimum delay
            await delay(300)

            const onContentLoad = () => {
                this.replaceContent(newUrl, newContent)
                oldContent.parentNode.removeChild(oldContent)
            }

            let interval = window.setInterval(() => {
                if (contentLoaded) {
                    onContentLoad()
                    window.clearInterval(interval)
                }
            }, 50)
        }

        pageTransition.out(oldContent, onTransitionStart, onTransitionEnd)
        this.popstate = false
    }

    // Enter page
    this.enterPage = (newContent) => {
        const onTransitionStart = () => {
            document.body.classList.add('on-transition')
            const t = window.setTimeout(() => {
                setLoading(false)
                window.clearTimeout(t)
            })
        }
        const onTransitionEnd = () => {
            document.body.classList.remove('on-transition')
        }
        pageTransition.in(newContent, onTransitionStart, onTransitionEnd)
    }

    // Replace new content
    this.replaceContent = async (newUrl, response) => {
        const wrapper = document.createElement('div')
        wrapper.innerHTML = response

        const newContent = wrapper.querySelector(contentSelector)
        pageTransition.set(newContent)
        this.main.appendChild(newContent)

        // Update new data
        this.updateData(newUrl, newContent)

        // Set scroll to top
        if (!this.popstate) {
            if (Scroll.scrollbar) {
                Scroll.scrollbar.scrollTo(0, 0, 0)
            } else {
                scroller.scrollTo(0, 0)
            }
        }
        
        // Dispatch event newContentLoad
        this.events.newContentLoad = new CustomEvent('newContentLoad', {
            detail: {
                container: newContent,
            }
        })
        document.dispatchEvent(this.events.newContentLoad)
        this.onNewContentLoad()
        
        this.popstate = false
        
        // Wait for videos loaded
        if (navigator.userAgent.indexOf("Safari") > -1 || /iPad|iPhone|iPod/.test(navigator.userAgent)) {
        } else {
            await videosLoaded(newContent)
        }

        // Page in
        this.enterPage(newContent)
    }

    // Update new data
    this.updateData = (newUrl, newContent) => {
        // Update menu active item
        const currentActiveItems = document.querySelectorAll('.menu-item.current-menu-item')
        currentActiveItems.forEach(menuItem => menuItem.classList.remove('current-menu-item'))
        const nextActiveItems = document.querySelectorAll(`.menu-item > a[href="${newUrl}"]`)
        nextActiveItems.forEach(menuItem => menuItem.closest('.menu-item').classList.add('current-menu-item'))

        // Update body classes
        const bodyClasses = newContent.querySelector(dataSelector).getAttribute('class')
        document.body.setAttribute('class', bodyClasses)

        // Update body dataset
        const bodyDataset = document.body.dataset
        const dataElement = newContent.querySelector(dataSelector)
        for (let data in bodyDataset) {
            if (dataElement.dataset['' + data + '']) {
                bodyDataset['' + data + ''] = dataElement.dataset['' + data + '']
            }
        }

        // Update metas
        const meta = {}
        meta.og = {}

        const createMetaElement = (meta, attribute, content) => {
            const element = document.createElement('meta')
            element.setAttribute(attribute, meta)
            element.content = content
            document.getElementsByTagName('head')[0].appendChild(element)
        }
        meta.description = document.querySelector('meta[name="description"]')
        meta.og.title = document.querySelector('meta[property="og:title"]')
        meta.og.description = document.querySelector('meta[property="og:description"]')
        meta.og.url = document.querySelector('meta[property="og:url"]')

        // Update title
        const title = newContent.querySelector(dataSelector).dataset.metaTitle
        document.title = title

        // Update meta og:title
        if (meta.og.title) {
            meta.og.title.setAttribute('content', title)
        } else {
            createMetaElement('og:title', 'property', title)
        }

        // Update meta description
        const description = newContent.querySelector(dataSelector).dataset.metaDescription
        if (meta.description) {
            meta.description.setAttribute('content', description)
        } else {
            createMetaElement('description', 'name', description)
        }
        // Update meta og:description
        if (meta.og.description) {
            meta.og.description.setAttribute('content', description)
        } else {
            createMetaElement('og:description', 'property', description)
        }

        // Update meta og:url
        if (meta.og.url) {
            meta.og.url.setAttribute('content', document.location)
        } else {
            createMetaElement('og:url', 'property', document.location)
        }

        // Update browser history
        this.updateHistory(title, newUrl)
    }

    // Update browser history
    this.updateHistory = (title, url) => {
        if (this.popstate) {
            history.replaceState(null, title, url)
        } else {
            history.pushState(null, title, url)
        }
    }
}

export default Navigation