import { Carousel } from './../components/carousel'
import { isTouchDevice, delay } from './../utils'
 
const Archive = new function () {
    let container = document
    let items     = []

    this.init = (newContainer = document) => {
        container = newContainer

        document.querySelectorAll('.pl-archive-item').forEach(element => {
            let iframes = []
            let videos  = []

            element.querySelectorAll('iframe').forEach(iframe => {
                iframes.push({
                    el: iframe,
                    player: new Vimeo.Player(iframe),
                })
            })

            element.querySelectorAll('video').forEach(video => {
                videos.push(video)
            })

            items.push({
                el: element,
                header: element.querySelector('.pl-archive-item__header'),
                content: element.querySelector('.pl-archive-item__content'),
                carousel: !isTouchDevice() ? new Carousel(element.querySelector('.pl-carousel')) : null,
                iframes: iframes,
                videos: videos,
            })
        })

        items.forEach((item) => {
            item.iframes.forEach(iframe => {
                iframe.player.pause()
            })
            item.videos.forEach(video => {
                video.pause()
            })

            item.header.addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation()
                this.toggleItem(item)
            })
        })
    }

    this.toggleItem = (item) => {
        if (item.el.classList.contains('is-open')) {
            this.closeItem(item)
        } else {
            this.openItem(item)
        }
    }
    
    this.openItem = async (item) => {
        item.el.classList.add('is-open')
        item.content.style.display = 'block'
        if (item.carousel) item.carousel.init()
        this.closeOthers(item)
        item.iframes.forEach(iframe => {
            iframe.player.play()
        })
        item.videos.forEach(video => {
            video.play()
        })
    }

    this.closeItem = async (item) => {
        item.el.classList.remove('is-open')
        
        await delay(200)
        item.iframes.forEach(iframe => {
            iframe.player.pause()
        })
        item.videos.forEach(video => {
            video.pause()
        })

        if (item.carousel) item.carousel.destroy()
        item.content.style.display = 'none'
    }

    this.closeOthers = (item) => {
        const self = item
        items.forEach((item) => {
            if (item !== self && item.el.classList.contains('is-open')) {
                this.closeItem(item)
            }
        })
    }

    this.update = (container = document) => {
        items = []
        this.init(container)
    }
}

export default Archive